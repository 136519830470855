import React, { useState } from 'react';
import './glowingButton.css';

interface Props {
  onAnimationEnd: () => void;
  label: any;
}




const GlowingButton: React.FC<Props> = ({ onAnimationEnd, label }) => {
  const [state, setState] = useState(false);


  const onClick = () => {
    if (state) {
      setState(false);
      return;
    }
    setState(true);
  }


  return (
    <button className={state ? "glowing-btn-c" : "glowing-btn"} onClick={onClick} onAnimationEnd={onAnimationEnd}>
      {label}
    </button>
  );
};

export default GlowingButton;