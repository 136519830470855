import React from 'react'

interface Props { text: any, onClick: any }

function Text(props: Props) {
    const { text, onClick } = props

    return (
        <div onClick={onClick} style={{ top: "0", left: "0", width: "100%", height: "100%" , position: "fixed", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
            <div className='glowing-txt' >
                <p>{text}</p>
            </div>
        </div>
    )
}

export default Text
