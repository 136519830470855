import React, { useState } from 'react'
import GlowingButton from './glowingButton'
import Fireworks from '@fireworks-js/react'
import Text from '../textScreen/text';
import TextHeart from '../textScreen/textHeart';

interface Props { }

function StartButton(props: Props) {
    const { } = props
    const [showFirework, setShowFirework] = useState(0);


    var btnTxt = <>
        <span className='glowing-txt'>T<span className='faulty-letter2'>Ä</span>Z<span className='faulty-letter'>I</span></span>
        
    </>;

    var text = <div>Liebs Täzi<br/>
    <br/>
    Mer wönsched der ganz <br/>
    gueti Besserig.<br/>
    Erhol di guet ond<br/>
    mer freued üs of dech.<br/>
    <br/>
    Dini Manne</div>;
    var text2 = "Gueti Besserig Täzi";

    const divStyles = {
        width: '200px',
        height: '200px',
    };

    const handleTxtClick1 = () => {
        setShowFirework(3);
        setTimeout(() => {
            if (showFirework === 3){
                setShowFirework(1);
            }
        }, 120000);
    }

    const handleTxtClick2 = () => {
        setShowFirework(1);
        setTimeout(() => {
            if (showFirework === 1){
                setShowFirework(0);
            }
        }, 90000);
    }

    const fireworkAnimationEnd = () => {
        setShowFirework(0);
    }

    const handleButtonClick = () => {
        setShowFirework(2);
        setTimeout(() => {
            if (showFirework === 2){
                setShowFirework(3);
            }
            
        }, 20000);
    };




    return (
        <div>

            {showFirework == 3 && (<Text text={ text } onClick={handleTxtClick2} />)}
            {showFirework == 2 && (<TextHeart text={ text2 } onClick={handleTxtClick1} />)}
            {showFirework == 1 && (
                <Fireworks
                    onClick={fireworkAnimationEnd}
                    options={{
                        rocketsPoint: {
                            min: 0,
                            max: 5
                        },
                        opacity: 0.2,
                        delay: {
                            min: 45,
                            max: 75
                        },
                    }}

                    style={{
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        position: 'fixed',
                        background: '#000'
                    }}

                />
            )}
            {showFirework == 0 && <GlowingButton onAnimationEnd={handleButtonClick} label={btnTxt} />}
        </div>
    )
}

export default StartButton
